html,body {
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
      }

      body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #fbfcfd;
      }
._2F38v___branding-Container__cls1 {

display: none !important;
}
